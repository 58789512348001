@import "vars";

h1 {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.1em;
  color: $base-color;
  @media screen and (max-width: 960px) {
    font-size: 24px;
  }
}
h2 {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0.05em;
  color: $base-black;
  margin-bottom: 15px;
  @media screen and (max-width: 960px) {
    font-size: 18px;
  }
}
h3 {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.05em;
  color: $base-black;
  margin-bottom: 15px;
  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
}
p {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: $base-black;
  margin-bottom: 15px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
}

.default-ui {
  &_header {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    border-bottom: 2px solid;
    &_title {

    }
    &_copy {

    }
  }
  &_element {
    &_content {
      padding-bottom: 30px;
      border-bottom: 2px solid;
    }
  }
}

.heading {
  padding: 213px 0;
  font-family: Montserrat, serif;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 0.1em;
    color: $base-color;
    margin-bottom: 26px;

  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    letter-spacing: 0.05em;
    color: $base-black;
  }
  &--bg {
    background-size: cover;
    background-position: center;
    h1, h2 {
      color: #fff;
    }
  }
  @media screen and (max-width: 1274px) {
    padding: 170px 0;
    h1 {
      font-size: 40px;
      line-height: 53px;
    }
    h2 {
      font-size: 30px;
      line-height: 41px;
    }
  }
  @media screen and (max-width: 960px) {
    padding: 119px 0;
    h1 {
      font-size: 26px;
      line-height: 34px;
    }
    h2 {
      font-size: 22px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 58px 0;
    h1 {
      font-size: 16px;
      line-height: 24px;
    }
    h2 {
      font-size: 15px;
      line-height: 19px;
    }
  }
}

.quote {
  font-family: Montserrat, serif;
  font-style: normal;
  background-position: center;
  background-size: cover;
  padding: 95px 0 125px 0;
  &_mark {
    img {
      width: 104px;
      @media screen and (max-width: 1274px) {
        width: 60px;
      }
    }
    &--bottom {
      text-align: right;
      img {
        transform: scale(-1, -1);
      }
    }
  }
  &_text {
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    margin-top: 49px;
    margin-bottom: 20px;
    @media screen and (max-width: 1274px) {
      font-size: 34px;
      line-height: 51px;
    }
    @media screen and (max-width: 760px) {
      font-size: 22px;
      line-height: 32px;
    }
    @media screen and (max-width: 480px) {
      font-size: 15px;
      line-height: 25px;
    }
  }
  &_author {
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    text-align: right;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-top: 49px;
    @media screen and (max-width: 1274px) {
      font-size: 34px;
      line-height: 51px;
    }
    @media screen and (max-width: 760px) {
      font-size: 22px;
      line-height: 32px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
}

.df-list {
  padding: 81px 0;
  &_ol {
    display: grid;
    grid-template-areas: "A A";
    gap: 166px;
    @media screen and (max-width: 1024px) {
      gap: 80px;
    }
    @media screen and (max-width: 760px) {
      gap: 45px;
    }
    @media screen and (max-width: 480px) {
      gap: 0x;
      grid-template-areas: "A";
    }
    ol {
      padding-left: 20px;
      @media screen and (max-width: 480px) {
        margin-bottom: 30px;
      }
      li {
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 760px) {
      padding: 40px 0;
    }
  }
}

.img-text-block {
  font-family: Montserrat, serif;
  font-style: normal;
  background-color: $base-color;
  color: #fff;
  padding: 257px 0;
  &_title {
    margin-bottom: 54px;
    font-weight: bold;
    font-size: 48px;
    line-height: 59px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  &_main {
    display: grid;
    grid-template-areas: "A A";
    gap: 72px;
    @media screen and (max-width: 960px) {
      grid-template-areas: "A";
      gap: 0;
    }
    &_img-block {
      img {
        margin-bottom: 54px;
      }
      &_author {
        margin-bottom: 30px;
      }
      &_description {

      }
      &_author, &_description {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
      }
    }
    &_text-block {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
      p {
        color: #fff;
      }
    }
  }
}

.about {
  padding-top: 108px;
  background-color: #B3B3B3;
  padding-bottom: 75px;
  &--light {
    background: #C5C0F4;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 50px;
  }
  &_description {
    color: #fff;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    &_title {
      width: 625px;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    &_name {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.1em;
      &--light {
        color: $light-blue;
      }
      @media screen and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    &_region {
      font-size: 37px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
    &_history {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: #FFFFFF;
      width: 615px;
      border-top: 2px solid #FFFFFF;
      border-bottom: 2px solid #FFFFFF;
      padding: 22px 0;
      &--light {
        color: $light-blue;
        border: none;
        padding: 0;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
        font-size: 20px;
      }
    }
  }

  &_slider {
    margin-top: 109px;
    .carousel__nav {
      @media screen and (max-width: 1440px) {
        display: none;
      }
    }
    .carousel__button.is-prev, .carousel__button.is-next {
      color: #ffffff;
    }
    .carousel__slide {
      width: 30%;
      margin: 0 18px;
      padding: 45px 32px;
      @media screen and (max-width: 960px) {
        width: 46%;
      }
      @media screen and (max-width: 648px) {
        width: 100%;
      }
    }
    .carousel__button.is-next {
      right: -80px;
    }
    .carousel__button.is-prev {
      left: -80px;
    }
    .carousel__dots {
      margin-top: 20px;
    }

    &_item {
      padding: 45px 39px;
      font-family: Montserrat, serif;
      font-style: normal;
      width: 400px;
      background: #FFFFFF;
      &--img {
        padding: 0 !important;
        .carousel__slide {
          padding: 0;
        }
      }
      &_img {
        width: 100%;
        height: 419px;
        object-fit: cover;
      }
      &--light {

      }
      &_icon {
        margin-bottom: 30px;
        img {
          width: 33px;
        }
      }
      &_title {
        font-weight: 600;
        font-size: 24px;
        line-height: 106.5%;
        letter-spacing: -0.03em;
        color: #8B8B8B;
        margin-bottom: 15px;
        text-transform: uppercase;
      }
      &_text {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #A0A0A0;
      }
    }
  }
}

.big-img {
  font-family: Montserrat, serif;
  font-style: normal;
  color: #FFFFFF;
  &_main {
    background-size: cover;
    background-position: center;
    &_title {
      padding: 559px 84px 20px 84px;
      font-weight: bold;
      font-size: 48px;
      line-height: 59px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &--light {
        color: $base-color;
      }
      @media screen and (max-width: 960px) {
        font-size: 20px;
        padding: 100px 15px 20px 15px;
      }
    }
    &_description {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      padding: 0 84px 44px 84px;
      &--light {
        color: $base-color;
      }
      @media screen and (max-width: 960px) {
        font-size: 14px;
        padding: 0 15px 30px 15px;
      }
    }
  }
  &_filter {
    background: linear-gradient(0deg, rgba(119, 109, 229, 0.3), rgba(119, 109, 229, 0.3));
    width: 100%;
    height: 100%;
  }
}