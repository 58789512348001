@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "vars";
@import "deafaut-elements";
@import "VersionForVisuallyImpaired";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body, html {
  padding: 0;
  margin: 0;
  font-family: Montserrat, serif;
}

.wrapper {
  width: 1274px;
  margin: auto;
  padding: 0 15px;
  box-sizing: border-box;
  @media screen and (max-width: 1274px) {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 0;

  &_logo {
    img {
      width: 272px;
      @media screen and (max-width: 760px) {
        width: 240px;
      }
      @media screen and (max-width: 480px) {
        width: 150px;
      }
    }
  }
  &_menu {
    @media screen and (max-width: 1050px) {
      display: none;
      position: absolute;
      background: #fff;
      top: 65px;
      left: 0;
      width: 100%;
      z-index: 100;
    }
    &--opened {
      display: block;
    }
    nav {
      ul {
        @media screen and (max-width: 1050px) {
          flex-direction: column;
        }
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        li {
          font-size: 10px;
          line-height: 24px;
          font-weight: bold;
          margin: 0 8px;
          @media screen and (max-width: 1050px) {
            margin-top: 10px;
            text-align: right;
          }
          a {
            color: $base-color;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  &_tools {
    display: flex;
    &_item {
      a {
        display: flex;
        height: 100%;
        align-items: center;
        img {
          @media screen and (max-width: 480px) {
            width: 30px;
            height: auto;
          }
        }
      }
      &--eye {
        margin-right: 31px;
        @media screen and (max-width: 480px) {
          margin-right: 15px;
        }
        img {
          width: 39px;
        }
      }
      &--search {
        img {
          width: 29px;
        }
      }
      &--toggle-menu {
        display: none;
        cursor: pointer;
        margin-left: 31px;
        img {
          width: 30px;
        }
        @media screen and (max-width: 1050px) {
          display: block;
        }
        @media screen and (max-width: 480px) {
          margin-left: 15px;
        }
      }
    }
  }


}

.structure {
  padding-top: 124px;
  padding-bottom: 105px;
  font-family: Montserrat, serif;
  font-style: normal;
  @media screen and (max-width: 960px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &_wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  &_list {
    width: 40%;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    &_wrapper {
      padding-left: 24px;
      border-left: 5px solid $base-color;
      margin-bottom: 134px;
      @media screen and (max-width: 960px) {
        margin-bottom: 50px;
      }
    }
    &_title {
      font-weight: 600;
      font-size: 1.75em;
      line-height: 56px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $base-color;
      margin-bottom: 38px;
      @media screen and (max-width: 960px) {
        font-size: 24px;
        margin-bottom: 15px;
      }
    }
    &_item {
      &:not(:last-child) {
        margin-bottom: 25px;
        @media screen and (max-width: 960px) {
          margin-bottom: 15px;
        }
      }
      a {
        font-weight: 500;
        font-size: 1.25em;
        line-height: 106.5%;
        color: #4B4B4B;
        text-decoration: none;
        @media screen and (max-width: 960px) {
          font-size: 14px;
        }
      }
    }
  }
  &_search {
    position: relative;
    @media screen and (max-width: 960px) {
      margin-bottom: 30px;
    }
    &_icon {
      position: absolute;
      width: 29px;
      top: 50%;
      margin-top: -15px;
      left: 20px;
    }
    input {
      width: 100%;
      height: 61px;
      background: #C4C4C4;
      border-radius: 10px;
      border: none;
      padding-left: 60px;
      &:focus {
        outline: none;
      }
    }
  }
  &_text {
    width: 50%;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    p {
      margin-bottom: 22px;
      font-weight: 500;
      font-size: 1.25em;
      line-height: 24px;
      color: #4B4B4B;
    }
  }
}

.page-header {
  background-color: $base-color;
  padding-top: 68px;
  padding-bottom: 69px;
  overflow: hidden;
  @media screen and (max-width: 960px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &_title {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-left: 105px;
    @media screen and (max-width: 960px) {
      padding-left: 0;
      font-size: 28px;
      line-height: 30px;
    }
  }
}

.main-slider {
  background-color: $base-color;
  padding-top: 68px;
  padding-bottom: 69px;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    padding: 0;
  }
  &_title {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-left: 105px;
  }
  &_wrapper {
    position: relative;
    @media screen and (max-width: 960px) {
      padding: 0;
    }
  }
  &_announcements {
    position: relative;
    @media screen and (max-width: 960px) {
      margin-bottom: 30px;
    }
    .carousel__button.is-prev, .carousel__button.is-next {
      color: #ffffff;
    }
    .carousel__slide {
      width: 27%;
      padding: 0;
      @media screen and (max-width: 1240px) {
        width: 31%;
      }
      @media screen and (max-width: 960px) {
        padding: 1rem;
        width: 33%;
      }
      @media screen and (max-width: 760px) {
        width: 50%;
      }
      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
    .carousel__dots {
      display: none;
    }
    &_title {
      font-family: Montserrat, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.1em;
      color: #FFFFFF;
      margin-bottom: 13px;
      @media screen and (max-width: 960px) {
        font-size: 24px;
        line-height: 56px;
        margin-bottom: 15px;
      }
    }
    &_items {
      font-family: Montserrat, serif;
      font-style: normal;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 760px) {
        padding: 0 40px;
      }
    }
    &_item {
      @media screen and (max-width: 960px) {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 10px;
        padding: 12px 25px;
        height: 100%;
      }
      &_date {
        font-weight: 500;
        font-size: 36px;
        line-height: 56px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        border-bottom: 3px solid #fff;
        margin-bottom: 14px;
        @media screen and (max-width: 1240px) {
          font-size: 30px;
        }
        @media screen and (max-width: 960px) {
          font-size: 20px;
          line-height: 56px;
        }
      }
      &_description {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        display: box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 1240px) {
          font-size: 18px;
        }
        @media screen and (max-width: 960px) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }

  &_carousel {
    margin-top: 69px;
    font-family: Montserrat, serif;
    font-style: normal;
    color: #FFFFFF;
    position: relative;
    @media screen and (max-width: 960px) {
      margin: 0;
    }
    .carousel__dots {
      margin-left: 80px;
      margin-top: -19px;
      top: 100%;
      @media screen and (max-width: 1200px) {
        margin-left: 0;
        margin-top: 0;
        top: calc(100% + 0.5rem);
      }
      @media screen and (max-width: 960px) {
        top: 90%;
      }
    }
    .carousel__slide {
      width: 100%;
      padding: 0;
    }
    .carousel__dot {
      width: 19px;
      height: 19px;
    }
    .has-dots {
      @media screen and (max-width: 960px) {
        margin: 0;
      }
    }
    .carousel__nav {
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    &_header {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.1em;
      position: absolute;
      left: calc(50% + 69px);
      @media screen and (max-width: 1200px) {
        position: inherit;
        left: 0;
        margin-bottom: 14px;
      }
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    &_bg {
      top: 50%;
      height: 340px;
      background: #C5C0F4;
      backdrop-filter: blur(10px);
      position: absolute;
      width: 100%;
      left: 49%;
      margin-top: -167px;
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    &_item {
      position: relative;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1200px) {
        background: #C5C0F4;
      }
      @media screen and (max-width: 960px) {
        display: block;
      }
      &_image {
        width: 50%;
        @media screen and (max-width: 960px) {
          width: 100%;
        }
        img {
          height: 468px;
          object-fit: cover;
          width: 100%;
        }
      }
      &_description {
        width: 50%;
        font-weight: 600;
        padding-left: 74px;
        @media screen and (max-width: 1200px) {
          padding-left: 20px;
        }
        @media screen and (max-width: 960px) {
          width: 100%;
          position: absolute;
          bottom: 100px;
        }
      }
      &_more {
        display: none;
        width: 181px;
        height: 39px;
        border: 1px solid #fff;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: #fff;
        text-decoration: none;
        @media screen and (max-width: 960px) {
          display: flex;
        }
      }
      &_title {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: .05em;
        margin-bottom: 28px;
        color: #FFFFFF;
        text-decoration: none;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        display: box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 1200px) {
          font-size: 30px;
        }
        @media screen and (max-width: 480px) {
          font-size: 20px;
        }
      }
      &_text {
        font-size: 24px;
        line-height: 30px;
        text-decoration: none;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 4;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        display: -webkit-box;
        display: box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 1200px) {
          font-size: 18px;
        }
        @media screen and (max-width: 960px) {
          display: none;
        }
      }
    }
  }
}

.news {
  padding: 74px 0;
  font-family: Montserrat, serif;
  font-style: normal;
  padding-bottom: 115px;
  @media screen and (max-width: 960px) {
    padding-bottom: 50px;
  }
  &_header {
    display: flex;
    margin-bottom: 42px;
    @media screen and (max-width: 1274px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
    &_title {
      @media screen and (max-width: 960px) {
        display: flex;
        justify-content: space-between;
      }
    }
    &_main {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.1em;
      color: $base-color;
      margin-bottom: 14px;
      text-transform: uppercase;
      @media screen and (max-width: 960px) {
        font-size: 24px;
        line-height: 56px;
        margin-bottom: 0;
      }
    }
    &_filter {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 960px) {
        flex-direction: column;
        justify-content: end;
      }
      &_item {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;
        color: $base-black;
        margin-right: 20px;
        &--active {
          color: $base-color;
        }
        @media screen and (max-width: 960px) {
          font-size: 12px;
          text-align: right;
          margin-right: 0;
        }
      }
    }
    &_tags {
      display: flex;
      margin-left: 194px;
      margin-top: 24px;
      flex-wrap: wrap;
      @media screen and (max-width: 1274px) {
        margin-left: 0px;
      }
      &_item {
        color: $base-color;
        text-decoration: none;
        padding-right: 56px;
      }
    }
  }
  &_articles {
    justify-content: space-between;
    flex-wrap: wrap;

    display: grid;
    gap: 26px;
    // grid-template-areas: "A A A";
    grid-template: 1fr / repeat(3, 1fr);
    @media screen and (max-width: 960px) {
      grid-template: 1fr/1fr;
    }
    .carousel__slide {
      width: 90%;
      padding: 0;
      margin-bottom: 25px;
      overflow: hidden;
      @media screen and (max-width: 960px) {
        width: 80%;
        padding: 1em;
      }
    }
    .carousel__nav {
      display: none;
    }
    .carousel__dots {
      display: none;
    }
  }
  &_article {
    color: $base-black;
    text-decoration: none;
    display: block;
    @media screen and (max-width: 960px) {
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border-radius: 13px;
      height: 100%;
    }
    &_img {
      margin-bottom: 29px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 233px;
        object-fit: cover;
      }
    }
    &_date {
      font-weight: bold;
      font-size: 18px;
      line-height: 106.5%;
      letter-spacing: 0.1em;
      margin-bottom: 7px;
      @media screen and (max-width: 960px) {
        padding: 0 24px;
        font-size: 9px;
      }
    }
    &_text {
      font-weight: 500;
      font-size: 24px;
      line-height: 106.5%;
      letter-spacing: -0.03em;
      @media screen and (max-width: 960px) {
        padding: 0 24px;
        padding-bottom: 19px;
        font-size: 14px;
      }
    }
  }
  &_media {
    font-family: Montserrat, serif;
    font-style: normal;
    display: flex;
    margin-bottom: 103px;
    justify-content: space-between;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
    &_main {
      width: 45%;
      text-decoration: none;
      @media screen and (max-width: 1274px) {
        width: 49%;
      }
      @media screen and (max-width: 960px) {
        width: 100%;
        margin-bottom: 40px;
      }
      &_image {
        position: relative;
        img {
          width: 100%;
        }
        &_tag {
          position: absolute;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          text-align: right;
          letter-spacing: 2px;
          text-transform: lowercase;
          color: #FFFFFF;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          top: 0;
          right: 29px;
        }
        &_date {
          position: absolute;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          text-align: right;
          letter-spacing: 2px;
          text-transform: lowercase;
          color: #FFFFFF;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          bottom: 22px;
          right: 29px;
        }
      }
      &_description {
        font-weight: 500;
        font-size: 36px;
        line-height: 106.5%;
        letter-spacing: -0.03em;
        color: #4B4B4B;
        margin-top: 34px;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
    }
    &_about {
      width: 38%;
      border-left: 5px solid $base-color;
      padding-left: 24px;
      @media screen and (max-width: 1274px) {
        width: 49%;
      }
      @media screen and (max-width: 960px) {
        width: 100%;
      }
      &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 26px;
        @media screen and (max-width: 480px) {
          flex-direction: column;
        }
        &_title {
          font-weight: 600;
          font-size: 36px;
          line-height: 56px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          color: $base-color;
          @media screen and (max-width: 1024px) {
            font-size: 24px;
          }
        }
        &_more {
          color: $base-color;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 2px;
          text-transform: uppercase;
          padding-top: 9px;
          text-decoration: none;
          @media screen and (max-width: 480px) {
            text-align: left;
          }
        }
      }
      &_items {

      }
      &_item {
        &:not(:last-child) {
          margin-bottom: 37px;
        }
        &_text {
          font-weight: 500;
          font-size: 24px;
          line-height: 106.5%;
          color: #4B4B4B;
          margin-bottom: 20px;
          position: relative;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 7px;
          }
          &--active {
            .news_media_about_item_text_border {
              display: block;
              position: absolute;
              left: -29px;
              border-left: 5px solid #C5C5C5;
              height: 100%;
            }
          }
          &_border {
            display: none;
          }
        }
        &_link {
          a {
            color: $base-black;
          }
        }
      }
    }
  }
}

.activity {
  font-family: Montserrat, serif;
  font-style: normal;
  padding-top: 142px;
  padding-bottom: 242px;
  @media screen and (max-width: 960px) {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  &_title {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: right;
    letter-spacing: 0.1em;
    color: #B3B3B3;
    margin-bottom: 85px;
    @media screen and (max-width: 960px) {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
  &_big-block {
    background-color: $base-color;
    padding: 18px 0;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 42px;
    @media screen and (max-width: 960px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &_blocks {
    /*
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
     */


    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
    }

    &_block {
      background-color: $base-color;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.015em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 41px;
      // width: 290px;
      height: 80px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 960px) {
        font-size: 14px;
        height: 50px;
        line-height: 20px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 360px) {
        font-size: 12px;
      }
    }
  }
}

.events {
  background-color: $base-color;
  color: #FFFFFF;
  font-family: Montserrat, serif;
  font-style: normal;
  overflow: hidden;
  &--rl {
    position: relative;
    padding-top: 138px;
    padding-bottom: 148px;
    @media screen and (max-width: 960px) {
      padding-top: 50px;
      padding-bottom: 70px;
    }
  }
  &_ovals {
    position: absolute;
    width: 300px;
    right: 0;
    top: 5%;
    margin-right: -150px;
    img {
      width: 100%;
    }
    @media screen and (max-width: 1050px) {
      display: none;
    }
  }
  &_header {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.1em;
    margin-bottom: 36px;
    @media screen and (max-width: 960px) {
      font-size: 24px;
    }
  }
  &_items {
    display: grid;
    gap: 37px 148px;
    width: 75%;

    /* ! */
    grid-template-areas:
    "A A"
    "B B";

    @media screen and (max-width: 1050px) {
      width: 100%;
      gap: 30px 30px;
    }

    @media screen and (max-width: 480px) {
      grid-template-areas: "A"
    }
  }
  &_item {
    &_title {
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.015em;
      text-decoration: none;
      color: #FFFFFF;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      -ms-line-clamp: 1;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      display: -webkit-box;
      display: box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
    }
    &_description {
      text-decoration: none;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      -ms-line-clamp: 4;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      display: -webkit-box;
      display: box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
    }
  }
}

.partners {
  max-width: 827px;
  margin: auto;
  padding-top: 58px;
  padding-bottom: 156px;
  width: 100%;
  @media screen and (max-width: 960px) {
    padding: 58px 15px 70px 15px;
  }
  &_title {
    margin-bottom: 122px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.1em;
    color: $base-color;
    @media screen and (max-width: 960px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
  &_slider {
    .carousel__slide {
      width: 33%;
      height: 183px;
      @media screen and (max-width: 960px) {

      }
    }
    .carousel__nav {
      display: none
    }
    &_slide {
      // : red;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

    }
  }
}

.footer {
  background-color: $base-color;
  padding-bottom: 362px;
  padding-top: 56px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding-bottom: 70px;
  }
  &_bg {
    position: relative;
  }
  &_main {
    position: relative;
    display: grid;
    justify-content: space-between;
    grid-template-areas: "A A A A";
    width: 100%;
    @media screen and (max-width: 760px) {
      grid-template-areas: "A";
      justify-content: center;
    }
    &_mobile-logo {
      display: none;
      @media screen and (max-width: 760px) {
        display: block;
      }
      &_img {
        width: 297px;
        margin-bottom: 25px;
      }
    }
    &_logo {
      @media screen and (max-width: 760px) {
        display: none;
      }
      &_img {
        margin-bottom: 20px;
        width: 180px;
      }
      &_socials {
        display: flex;
        justify-content: space-between;
        &_item {
          img {
            height: 22px;
          }
        }
      }
    }
    &_menu {
      @media screen and (max-width: 760px) {
       display: none;
      }
      nav {
        ul {
          list-style: none;
          li {
            margin-bottom: 5px;
            a {
              text-decoration: none;
              font-weight: bold;
              font-size: 12px;
              line-height: 24px;
              text-transform: uppercase;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    &_contacts {
      @media screen and (max-width: 760px) {
        text-align: center;
      }
      &_title {
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 24px;
        line-height: 56px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
      }
      &_row {
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        text-transform: capitalize;
        color: #FFFFFF;
      }
    }
    &_map {
      img {
        width: 308px;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
}
.circle-bg {
  position: absolute;
  margin-left: -505px;
  left: 0;
  top: 15px;
  img {
    width: 932px;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
  @media screen and (max-width: 760px) {
    display: none;
  }
}
.media {
  background-color: #8D8D8D;
  padding-top: 109px;
  padding-bottom: 143px;
  @media screen and (max-width: 960px) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  &_header {
    display: flex;
    margin-bottom: 42px;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
    &_title {

    }
    &_main {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.1em;
      color: #fff;
      margin-bottom: 14px;
    }
    &_filter {
      display: flex;
      justify-content: space-between;
      &_item {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
      }
    }
    &_tags {
      display: flex;
      margin-left: 194px;
      margin-top: 24px;
      font-size: 14px;
      flex-wrap: wrap;
      @media screen and (max-width: 1024px) {
        margin-left: 60px;
      }
      @media screen and (max-width: 960px) {
        margin-left: 0px;
      }
      &_item {
        padding: 0 28px;
        color: #fff;
        text-decoration: none;
        @media screen and (max-width: 960px) {
          padding: 0 14px 0 0;
        }
      }
    }
  }
  &_gallery {
    &_slider {
      .carousel__slide {
        width: 33%;
        height: 281px;
        overflow: hidden;
        margin-right: 2%;
        padding: 0;
        @media screen and (max-width: 960px) {
          width: 50%;
        }
        @media screen and (max-width: 760px) {
          width: 100%;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.text-page {
  padding-bottom: 100px;
  padding-top: 100px;
  @media screen and (max-width: 960px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.spoiler {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
  border-radius: 10px;
  padding: 9px 13px;
  &_header {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    &_title {

    }
    &_arrow {
      width: 26px;
      img {
        width: 100%;
      }
    }
  }
  &_content {
    overflow: hidden;
    height: 0px;
    transition: 1s;
    &--active {
      height: auto;
    }
    iframe {
      width: 100%;
      border: none;
      height: 700px;
    }
  }
}

.breadcrumbs {
  margin: 0;
  padding: 40px;
  background: $base-color;
  display: block;
  li {
    display: inline-block;
    a {
      position: relative;
      font-size: 19px;
      color: #FFFFFF;
      text-transform: uppercase;
      padding-right: 20px;
      margin-right: 10px;
      text-decoration: none;
      @media screen and (max-width: 760px) {
        font-size: 14px;
      }
      &:after {
        content: "";
        width: 9px;
        height: 8px;
        border-top:2px solid #555;
        border-right:2px solid #555;
        position:absolute;
        top: 6px;
        right: 0;
        transform:rotate(45deg);
        display: block;
      }
    }
    &:last-of-type a {
      color: $base-black;
      font-weight: bold;
      &:after { display: none; }
    }
  }
}
