.isVersionForVisuallyImpaired {
  * {
    background: #fff !important;
    background-image: none !important;
    box-sizing: border-box;
    color: #117 !important;
    font-style: normal !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 18px;
    font-weight: bold !important;
    overflow: visible;
    line-height: 1.2em !important;
    letter-spacing: 0.05em !important;
    text-shadow: none;
  }
  body {background: #fff !important;}
  h1 {
    font-size: xx-large;
  }
  h2, h3 {
    font-size: x-large;
  }
  h4, h5, h6 {
    font-size: 22px;
  }

  div#wrapper {
    width: 1200px !important;
  }
  div#leftcolumn {
    width: 24% !important;
    float: left;
  }
  div#maincolumn {
    width: 72% !important;/**/
    margin-top: 0 !important;
    padding-top:0;
  }
  div#rightcolumn, div#tabarea {
    display: none !important;
    visibility: hidden;
    width: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  p, li {
    font-size: 18px;
  }
  a:link {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }

  #spec {
    width: 300px !important;
    height: 30px !important;
    border: 1px black outset !important;
    margin-bottom: 5px;
  }

  /*
  #nav, #menu{
      height: 8em !important;
  }
  #nav a, #menu a {
      height: 1.6em !important;
  }
  */

  .imgPU {cursor:pointer;}
  img.imgPU {
    width:30px;
    margin: 3px 5px 0;
    /*	box-shadow: 1px 1px 1px 1px #666; border-radius:2px; */
    transform:'none';
  }
  img.imgPU:hover{
    box-shadow: 0 0 1px 1px #999; border-radius:2px; transform:scale(1.1);
  }

  input.imgPU:hover {box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 5px rgba(82, 168, 236, 0.6);}

  div#special-panel {
    background: linear-gradient(to bottom , #aaa 0%, #eee 90%, #fafafa) #d7d7d7 !important;
    border-bottom: 1px solid #c7c7c7;
    border-radius: 0 0 5px 5px;
    color: #000;
    font-size: 18px;
    /*  height:50px; */
    left: 0;
    padding: 1px 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 20000;
  }
  div#special-panel div, div#special-panel img {background:none !important;}

  // кастомные
  img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray;
  }

  .vfvi-hided {
    display: none;
  }
}